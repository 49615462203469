import { Dropdown, Text, Tooltip, Button, Banner } from '@loadsmart/miranda-react';
import LsLogo from 'assets/images/ls_logo_ma.png'
import ReportHeaderComponent from './components/reportHeader/ReportHeader';
import Header from './components/header/Header';
import ReportComponent from './components/report/Report';
import { RequestType } from '../domain/RequestType';
import { VscGraph, VscEye } from "react-icons/vsc";
import { PiArrowLineLeftBold, PiArrowLineRightBold } from "react-icons/pi";
import { useState, useEffect } from 'react'
import ZendeskDialog from './components/zendesk/TryNowZendeskDialog'
import { useRequestZendesk } from './hooks/useRequestZendesk'
import { AnalyticsEvent } from 'core/analytics/events'
import { useAnalytics } from 'core/analytics/useAnalytics'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
 
import {
    StyledActionDiv,
    StyledDropDown,
    StyledLSLogoImg,
    StyledPageContainerDiv,
    StyledReportWrapper,
    StyledSideMenuDiv,
    StyledSubTitleDiv
} from './DemoPage.styles'

const Demo = () => {
    const [isReportLoading, setIsReportLoading] = useState(true)
    const [accessToken, setAccessToken] = useState('')
    const [groupId, setGroupId] = useState('')
    const [reportId, setReportId] = useState('')

    const [isCollapsed, setIsCollapsed] = useState(false)

    //mixpanel init
    const { track } = useAnalytics();

    const navigate = useNavigate()
    useEffect(() => {
        const userAgent = window.navigator.userAgent;
        const isMobile = /Mobi/i.test(userAgent);
        
        if (isMobile) {
          navigate('/mobile-not-supported');
        }
      }, []);

    async function getPBIReportDemo() {
        try {
            const response = await fetch(`${import.meta.env.VITE_BASE_API_URL}/pbi_report_demo`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setIsReportLoading(false);
            setAccessToken(data['embed_token'][0]);
            setGroupId(data['group_id']);
            setReportId(data['report_id']);
            track(AnalyticsEvent.OpenDemo);
        } catch (error) {
            console.error('Failed to fetch PBI report demo:', error);
            throw error;
        }
    }

    useEffect(() => {
        getPBIReportDemo();
    }, []);

    const [requestType, setRequestType] = useState<RequestType>()
    const [showRequestDialog, setShowRequestDialog] = useState(false)

    const BannerWrapper = styled.div`
        text-align: left;
        position: absolute;
        width: 85%;
        margin-left: 15%;
        z-index: 1000;
    `
    const DialogFeedbackType = {
        NONE: '',
        SUCCESS: 'success',
        FAILED: 'failed',
    }
    const [showDialogFeedback, setShowDialogFeedback] = useState(DialogFeedbackType.NONE)
    const { hasError, hasSuccess, isSendingRequest, requestZendesk } = useRequestZendesk()

    useEffect(() => {
        if (hasError) {
            setShowDialogFeedback(DialogFeedbackType.FAILED)
            setShowRequestDialog(false)
        }

        if (hasSuccess) {
            setShowDialogFeedback(DialogFeedbackType.SUCCESS)
            setShowRequestDialog(false)
        }
    }, [hasError, hasSuccess])

    const toggleZendeskDialog = (requestType: RequestType) => {
        setRequestType(requestType)
        setShowRequestDialog(true)
    }

    const handleOnSendFeedback = async (name: string, company: string, email: string, phone: string) => {
        if (requestType) {
            requestZendesk(requestType, name, company, email, phone)
        }
        setShowRequestDialog(false)
    }

    const handleDismiss = () => {
        setShowDialogFeedback(DialogFeedbackType.NONE)
    }

    const toggleMenuCollapse = () => {
        setIsCollapsed(!isCollapsed)
    }

    const reportName = 'Demo Report'
    return (
        <StyledPageContainerDiv>
            {showDialogFeedback === DialogFeedbackType.SUCCESS && (
                <BannerWrapper>
                    <Banner variant="success" onDismiss={handleDismiss} dismissible>
                        <Banner.Title>Success</Banner.Title>
                        <Banner.Description>
                            Your request has been sent. Soon we'll get in touch.
                        </Banner.Description>
                    </Banner>
                </BannerWrapper>
            )}
            {showDialogFeedback === DialogFeedbackType.FAILED && (
                <BannerWrapper>
                    <Banner variant="danger" onDismiss={handleDismiss} dismissible>
                        <Banner.Title>Your request was not sent.</Banner.Title>
                        <Banner.Description>
                            Please contact Loadsmart team to handle the request.
                        </Banner.Description>
                    </Banner>
                </BannerWrapper>
            )}
            <StyledSideMenuDiv className={isCollapsed ? 'collapsed' : ''}>
                    <Button variant='icon' size='large' onClick={toggleMenuCollapse} style={{ color: '#fbd709',  marginLeft: 'calc(100% - 25px)', marginTop: '7%'}}>
                        {isCollapsed ? <PiArrowLineRightBold size={'25'} color='#005454	'/> : <PiArrowLineLeftBold size={'25'} color='#005454	'/>}
                    </Button>
                    {!isCollapsed && (
                        <>
                        <StyledLSLogoImg src={LsLogo} alt="LS Logo" />
                        <StyledSubTitleDiv>
                            <Text color="color-neutral-60" variant="body-md-bold">
                                REPORTS
                            </Text>
                        </StyledSubTitleDiv>
                        <div>
                            <StyledDropDown size="default">
                                <Dropdown.Trigger>
                                    Demo Report
                                </Dropdown.Trigger>
                                <Dropdown.Menu>
                                    <Dropdown.Item>{reportName}</Dropdown.Item>
                                </Dropdown.Menu>
                            </StyledDropDown>
                        </div>
                        <StyledSubTitleDiv>
                            <Text color="color-neutral-60" variant="body-md-bold">
                                INTEGRATIONS
                            </Text>
                        </StyledSubTitleDiv>
                        <StyledActionDiv>
                            <Tooltip content="Integrations Overview" message="Not available on demo mode" trigger="hover">
                                <Text
                                    color="color-neutral-10"
                                    variant="body-md-bold"
                                >
                                    Integrations Overview
                                </Text>
                            </Tooltip>
                        </StyledActionDiv>
                        </>
                )}
            </StyledSideMenuDiv>
            <StyledReportWrapper className={isCollapsed ? 'collapsed' : ''}>
                <Header />
                <ReportHeaderComponent reportName={reportName} />

                <ReportComponent
                    groupId={groupId}
                    reportId={reportId}
                    accessToken={accessToken}
                    isReportLoading={isReportLoading}
                />
                <Banner variant="warning">
                    <Banner.Icon><VscGraph color='#005454' size='big' /></Banner.Icon>
                    <Banner.Title>Liked something here? Didn't find exactly what you were looking for?</Banner.Title>
                    <Banner.Description>Take your insights further with custom reports. Unlock the full potential of your data by partnering with our solutions team to create on-demand tailored metrics, KPIs, views, filters and charts to meet your specific needs.</Banner.Description>
                </Banner>
                <Banner variant="warning">
                    <Banner.Icon><VscEye color='#005454' size='big' /></Banner.Icon>
                    <Banner.Title>Looking for even more efficiency?</Banner.Title>
                    <Banner.Description>Explore our custom automated workflows for seamless integration and regularly scheduled reports delivered when you need them.</Banner.Description>
                    <Banner.Actions>
                        <Banner.ActionPrimary onClick={() => toggleZendeskDialog(RequestType.TryItNow)}>
                            Contact us today for a quote
                        </Banner.ActionPrimary>
                    </Banner.Actions>
                </Banner>
            </StyledReportWrapper>
            <ZendeskDialog
                open={showRequestDialog}
                disabled={isSendingRequest}
                onCancelRequest={() => setShowRequestDialog(false)}
                onSendRequest={handleOnSendFeedback}
            />
        </StyledPageContainerDiv>
    )
}

export default Demo
